import {
  Button,
  Checkbox,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  InputWithIcon,
  Lucide,
} from "@components/common";
import { IPhoneIcon, PinIcon } from "@components/icons";
import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { BiLoaderAlt } from "react-icons/bi";
import type { OffersPageFormSchemaInterface } from "validations/offerspage.validation";
import { getOffersPageFormSchema } from "validations/offerspage.validation";
import { useJoinOffers } from "store/joinOffers.store";
import { Link } from "@chakra-ui/react";

type FormValues = OffersPageFormSchemaInterface;

export type PhoneNumberAndZipFormProps = {
  onSubmit: (payload: FormValues) => void;
  isSubmitting: boolean;
  isZipCodeRequired: boolean;
  isBrowserGeolocationAccepted: boolean;
  buttonDivStyle?: string | undefined;
  buttonSize?: "default" | "sm" | "lg" | "icon" | null | undefined;
  buttonCopy?: string | undefined;
  isPartnersLegal?: boolean;
  defaultCustomerPhoneNumber: string;
};

export const PhoneNumberAndZipForm = ({
  onSubmit,
  isSubmitting,
  isZipCodeRequired,
  isPartnersLegal = false,
  defaultCustomerPhoneNumber,
}: PhoneNumberAndZipFormProps) => {
  const { customerPhoneNumber } = useJoinOffers();

  const form = useForm<FormValues>({
    mode: "onTouched",
    resolver: zodResolver(getOffersPageFormSchema(isZipCodeRequired)),
    defaultValues: {
      phoneNumber: defaultCustomerPhoneNumber ?? customerPhoneNumber,
      zipCode: "",
      isAisleOpted: false,
      isPartnersOpted: true,
    },
  });

  const isOptedIn = form.watch("isAisleOpted");

  useEffect(() => {
    form.reset({
      phoneNumber: form.getValues("phoneNumber"),
      zipCode: isZipCodeRequired ? "" : undefined,
      isAisleOpted: form.getValues("isAisleOpted"),
      isPartnersOpted: form.getValues("isPartnersOpted"),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isZipCodeRequired]);

  const onSubmitHandle: SubmitHandler<FormValues> = async (data) => {
    onSubmit(data);
  };

  return (
    <Form {...form}>
      <div className="w-full pt-2 pb-4 space-y-4 md:pt-8 md:pb-8">
        <FormField
          control={form.control}
          name="phoneNumber"
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <InputWithIcon
                  id="tel"
                  {...field}
                  decorator={<IPhoneIcon />}
                  placeholder={"Phone Number..."}
                  autoComplete="tel"
                  aria-describedby="phonenumbercapture"
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        {isZipCodeRequired && (
          <FormField
            control={form.control}
            name="zipCode"
            render={({ field }) => (
              <FormItem>
                <InputWithIcon
                  id="postal-code"
                  {...field}
                  decorator={<PinIcon />}
                  placeholder="Zip code"
                  autoComplete="postal-code"
                />
                <FormMessage />
              </FormItem>
            )}
          />
        )}
        <div className="flex flex-col items-start justify-start w-full gap-4 ">
          <FormField
            control={form.control}
            name="isAisleOpted"
            render={({ field }) => (
              <FormItem>
                <FormItem className="flex flex-row items-start space-x-3 space-y-0">
                  <FormControl>
                    <div className="relative flex justify-center">
                      <Checkbox
                        onCheckedChange={(event) => field.onChange(event)}
                        className="mt-1 border-2 border-indigo-600 relative bg-white z-10"
                        id="aisle-opted"
                        aria-describedby="aisle-opted-field"
                      />
                    </div>
                  </FormControl>
                  <FormLabel
                    className="font-normal text-gray-500 text-xs"
                    htmlFor="aisle-opted-field-form-item"
                  >
                    {isPartnersLegal ? (
                      <p>
                        By checking this box I provide my express written
                        consent to receive recurring marketing text messages
                        from Aisle for{" "}
                        <Link
                          href="/partners"
                          className="font-medium text-gray-800 underline"
                        >
                          partner
                        </Link>{" "}
                        offers. Consent is not a condition of purchase. Msg &
                        data rates may apply. Reply HELP for help and STOP to
                        cancel. I agree to aisle&apos;s and{" "}
                        <Link
                          href="/terms-of-service"
                          className="font-medium text-gray-800 underline"
                        >
                          Terms and Service
                        </Link>{" "}
                        and{" "}
                        <Link
                          href="/privacy-policy"
                          className="font-medium text-gray-800 underline"
                        >
                          Privacy Policy
                        </Link>
                        .
                      </p>
                    ) : (
                      <p>
                        By checking this box I provide my express written
                        consent to receive recurring marketing text messages
                        from aisle on behalf of any brand I claim an offer for
                        via automated means, including by automatic telephone
                        dialing system. Consent is not a condition of purchase.
                        Msg & data rates may apply. Reply HELP for help and STOP
                        to cancel. I agree to aisle&apos;s{" "}
                        <Link
                          href="/terms-of-service"
                          className="font-medium text-gray-800 underline"
                        >
                          Terms and Service
                        </Link>{" "}
                        and{" "}
                        <Link
                          href="/privacy-policy"
                          className="font-medium text-gray-800 underline"
                        >
                          Privacy Policy
                        </Link>
                        .
                      </p>
                    )}
                  </FormLabel>
                </FormItem>
              </FormItem>
            )}
          />
        </div>
      </div>
      <Button
        variant="default"
        className={
          "transition-all rounded-full w-full disabled:bg-slate-400 hover:disabled:bg-slate-400"
        }
        size={"lg"}
        disabled={!isOptedIn || isSubmitting}
        onClick={form.handleSubmit(onSubmitHandle)}
      >
        {isSubmitting && <BiLoaderAlt className="w-4 h-4 mr-2 animate-spin" />}
        <Lucide icon="Rocket" className="inline mr-2 stroke-[1.5px]" />
        Sign Up & Start Earning
      </Button>
      <p className="text-slate-500 text-sm mt-2 text-center">
        Opt out at anytime with one tap, no commitment!
      </p>
    </Form>
  );
};
