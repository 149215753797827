import {
  Box,
  ButtonProps,
  Flex,
  FormControlProps,
  Heading,
  HeadingProps,
  IconProps,
  InputElementProps,
  InputGroupProps,
  InputProps,
  StackProps,
  TextProps,
  FlexProps,
  CheckboxProps,
} from "@chakra-ui/react";
import Image from "next/image";
import { FC, useState } from "react";
import { DefaultLegalCopy } from "./DefaultLegalCopy";
import JoinAisleModal from "./Offers/JoinAisleModal";
import { SubscriptionForm } from "./SubscriptionForm";
import { SubscriptionContainerFields } from "./form.types";

export interface SubscriptionFormContainerProps {
  brandApiKey: string;
  backgroundColor?: string;
  backgroundImage?: string;
  backgroundSize?: string;
  campaignPhoneNumber: string;
  headerCopy: string | JSX.Element;
  headerCopyStyle?: HeadingProps;
  subheaderCopyStyle?: HeadingProps;
  logoUrl?: string;
  logoWidth?: string;
  logoHeight?: string;
  textColor?: string;
  productImage?: string;
  subscriptionFormStyle?: HeadingProps;
  productImageWidth?: string;
  productImageHeight?: string;
  subheaderCopy?: string | JSX.Element;
  formContainerStyle?: FlexProps;
  formInputContainerStyle?: FormControlProps;
  formInputGroupStyle?: InputGroupProps;
  formInputHeight?: string | string[];
  formButtonStyle?: ButtonProps;
  formButtonCopy?: string;
  formInputStyle?: InputProps;
  formInputPlaceholder?: string;
  withLegalCopy?: boolean;
  legalCopy?: JSX.Element | string;
  withPhoneIcon?: boolean;
  containerStyle?: FlexProps;
  formInputIconStyle?: IconProps;
  formInputIconContainerStyle?: InputElementProps;
  legalCopyStyle?: TextProps;
  legalCopyContainerStyle?: FlexProps;
  withProductImageBelowForm?: boolean;
  withLogoBelowForm?: boolean;
  isLegalCopyAboveInputButton?: boolean;
  isLegalCopyAboveSubscribeButton?: boolean;
  customerZipCode?: string;
  isRequireFirstName?: boolean;
  isRequireLastName?: boolean;
  isRequireEmail?: boolean;
  isRequireEmailOptional?: boolean;
  isRequireZipCode?: boolean;
  optInToBrandAndAisle?: boolean;
  optInToBrandAndAisleStyle?: TextProps;
  optInToBrandAndAisleContainerStyle?: StackProps;
  isCampaignWithMultipleLandingPages?: boolean;
  formFlexDirection?: string[] | string;
  formMargin?: string;
  formWidth?: string[] | string;
  isConsentRequired?: boolean;
  subscriptionFormConsentContainerStyle?: FlexProps;
  subscriptionFormCheckboxStyle?: CheckboxProps;
  successToastMessage?: string;
  optInToBrandAndAisleTheme?: "light" | "dark";
  isDonateOptIn?: boolean;
  isPepsiOptIn?: boolean;
  isDrinkingAge?: boolean;
  isLegalEnglish?: boolean;
  legalDrinkingAgeCopy?: string | React.ReactElement;
  formEmailInputPlaceholder?: string;
  customFields?: SubscriptionContainerFields[];
}

export const SubscriptionFormContainer: FC<SubscriptionFormContainerProps> = ({
  customerZipCode,
  campaignPhoneNumber,
  legalCopyContainerStyle,
  legalCopyStyle,
  backgroundColor,
  backgroundImage,
  backgroundSize,
  legalCopy = <DefaultLegalCopy />,
  withLegalCopy,
  brandApiKey,
  headerCopy,
  headerCopyStyle,
  subheaderCopyStyle,
  subheaderCopy,
  subscriptionFormStyle,
  textColor = "#1D2939",
  formContainerStyle,
  formInputContainerStyle,
  formInputHeight = "44px",
  formButtonStyle,
  formButtonCopy = "Submit",
  formInputStyle,
  formInputPlaceholder = "Enter phone number...",
  formInputGroupStyle,
  isLegalCopyAboveInputButton = false,
  isLegalCopyAboveSubscribeButton = false,
  formInputIconStyle,
  formInputIconContainerStyle,
  withPhoneIcon,
  logoUrl,
  withLogoBelowForm,
  logoWidth,
  logoHeight,
  productImage,
  withProductImageBelowForm,
  productImageWidth,
  productImageHeight,
  isRequireFirstName,
  isRequireLastName,
  isRequireEmail,
  isRequireEmailOptional,
  isRequireZipCode,
  optInToBrandAndAisle = false,
  optInToBrandAndAisleStyle,
  optInToBrandAndAisleContainerStyle,
  isCampaignWithMultipleLandingPages = false,
  formFlexDirection = "column",
  formMargin,
  formWidth,
  isConsentRequired = false,
  subscriptionFormConsentContainerStyle,
  subscriptionFormCheckboxStyle,
  successToastMessage,
  optInToBrandAndAisleTheme,
  isDonateOptIn,
  isPepsiOptIn,
  isDrinkingAge,
  isLegalEnglish,
  legalDrinkingAgeCopy,
  formEmailInputPlaceholder,
  customFields = [],
}: SubscriptionFormContainerProps) => {
  const [customerPhoneNumber, setCustomerPhoneNumber] = useState("");
  if (
    isConsentRequired &&
    isLegalCopyAboveInputButton &&
    isLegalCopyAboveSubscribeButton
  ) {
    throw new Error(
      "When using isConsentRequired, you can only have isLegalCopyAboveInputButton or isLegalCopyAboveSubscribeButton set to true"
    );
  }
  const LegalCopyComponent = withLegalCopy ? (
    <Flex
      mt="48px"
      alignSelf="center"
      w={["90%", "40%"]}
      justify="center"
      textAlign="center"
      {...legalCopyContainerStyle}
    >
      <Flex
        fontSize="10px"
        color="#667085"
        fontWeight="400"
        lineHeight="12.1px"
        {...legalCopyStyle}
      >
        {legalCopy}
      </Flex>
    </Flex>
  ) : null;

  return (
    <>
      <Flex
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        flexDirection={
          formFlexDirection === "column" ? "column" : formFlexDirection
        }
        backgroundImage={backgroundImage}
        backgroundColor={backgroundColor}
        w={formWidth ?? "auto"}
        margin={formMargin ?? "0px"}
      >
        <Box
          backgroundColor={backgroundColor}
          backgroundImage={"inherit"}
          backgroundSize={backgroundSize}
        >
          {!!headerCopy && (
            <Heading
              color={textColor}
              fontWeight="bold"
              fontSize={["32px", "48px", "64px"]}
              textAlign="center"
              m="auto"
              w={["90%", "80%"]}
              {...headerCopyStyle}
              {...subscriptionFormStyle}
            >
              {headerCopy}
            </Heading>
          )}

          {!!subheaderCopy && (
            <Heading
              color={textColor}
              fontFamily="Thicccboi"
              fontWeight="normal"
              fontSize={["16px", "24px", "32px"]}
              textAlign="center"
              m="auto"
              mt="15px"
              w={["90%", "80%"]}
              {...subheaderCopyStyle}
            >
              {subheaderCopy}
            </Heading>
          )}
        </Box>

        <Flex>
          <SubscriptionForm
            customerZipCode={customerZipCode}
            campaignPhoneNumber={campaignPhoneNumber}
            brandApiKey={brandApiKey}
            formContainerStyle={formContainerStyle}
            formInputContainerStyle={formInputContainerStyle}
            formInputGroupStyle={formInputGroupStyle}
            formInputHeight={formInputHeight}
            formButtonStyle={formButtonStyle}
            formButtonCopy={formButtonCopy}
            formInputStyle={formInputStyle}
            formInputPlaceholder={formInputPlaceholder}
            withPhoneIcon={withPhoneIcon}
            formInputIconStyle={formInputIconStyle}
            formInputIconContainerStyle={formInputIconContainerStyle}
            isLegalCopyAboveInputButton={isLegalCopyAboveInputButton}
            isLegalCopyAboveSubscribeButton={isLegalCopyAboveSubscribeButton}
            LegalCopyComponent={
              isLegalCopyAboveSubscribeButton || isLegalCopyAboveInputButton
                ? LegalCopyComponent
                : null
            }
            isRequireFirstName={isRequireFirstName}
            isRequireLastName={isRequireLastName}
            isRequireEmail={isRequireEmail}
            isRequireEmailOptional={isRequireEmailOptional}
            isRequireZipCode={isRequireZipCode}
            optInToBrandAndAisle={optInToBrandAndAisle}
            optInToBrandAndAisleStyle={optInToBrandAndAisleStyle}
            optInToBrandAndAisleContainerStyle={
              optInToBrandAndAisleContainerStyle
            }
            isCampaignWithMultipleLandingPages={
              isCampaignWithMultipleLandingPages
            }
            isConsentRequired={isConsentRequired}
            subscriptionFormConsentContainerStyle={
              subscriptionFormConsentContainerStyle
            }
            subscriptionFormCheckboxStyle={subscriptionFormCheckboxStyle}
            successToastMessage={successToastMessage}
            optInToBrandAndAisleTheme={optInToBrandAndAisleTheme}
            isPepsiOptIn={isPepsiOptIn}
            isDonateOptIn={isDonateOptIn}
            isDrinkingAge={isDrinkingAge}
            isLegalEnglish={isLegalEnglish}
            legalDrinkingAgeCopy={legalDrinkingAgeCopy}
            formEmailInputPlaceholder={formEmailInputPlaceholder}
            customFields={customFields}
            updatePhoneNumber={(phoneNumber) =>
              setCustomerPhoneNumber(phoneNumber)
            }
          />

          <JoinAisleModal customerPhoneNumber={customerPhoneNumber} />
        </Flex>
        {(withLegalCopy || (optInToBrandAndAisle && withLegalCopy)) &&
        !(isLegalCopyAboveSubscribeButton || isLegalCopyAboveInputButton)
          ? LegalCopyComponent
          : null}
        {!!logoUrl && withLogoBelowForm && (
          <Box
            width={logoWidth}
            m="auto"
            mb="20px"
            height={logoHeight}
            position="relative"
          >
            <Image layout="fill" alt="aisle-logo" src={logoUrl} />
          </Box>
        )}
        {!!productImage && withProductImageBelowForm && (
          // True size of that image is 2000px x 452px. Displaying it at 20% of those dimensions
          <Flex
            width={productImageWidth}
            m="auto"
            mb="20px"
            height={productImageHeight}
            position="relative"
          >
            <Image layout="fill" alt="aisle-logo" src={productImage} />
          </Flex>
        )}
      </Flex>
    </>
  );
};
