import * as gtag from "@lib/gtag";
import Image from "next/image";
import React, { useEffect } from "react";

import { Button, Dialog, DialogContent } from "@components/common";
import { ReferralEnums } from "enums/referral.enum";
import { useGeolocationData } from "hooks/useGeolocationData";
import { useRouter } from "next/router";
import { useSubscribeOfferMutation } from "services/queries/offers";
import { useCustomerStore } from "store/customer.store";
import { useJoinOffers } from "store/joinOffers.store";
import { useQueryStore } from "store/query.store";
import { OffersPageFormSchemaInterface } from "validations/offerspage.validation";
import { PhoneNumberAndZipForm } from "./PhoneNumberAndZipForm";
import { LiveTransactionFeed } from "./LiveTransactionFeed";
import {
  AISLE_CAMPAIGN_BRAND_API_KEY,
  AISLE_CAMPAIGN_PHONENUMBER,
} from "constants/aisle.constant";

export type JoinAisleProps = {
  customerPhoneNumber: string;
};

export const JoinAisleModal = ({ customerPhoneNumber }: JoinAisleProps) => {
  const router = useRouter();
  const storedQueryParams = useQueryStore.use.stored();
  const referrerUrl = useCustomerStore.use.referrerUrl();

  const {
    isPartner,
    isUserOptedIn,
    campaignPhoneNumber,
    setCustomerPhoneNumber,
    isSubscriptionFormSubmittedSuccessfully,
  } = useJoinOffers();

  const landingPageSource =
    router.pathname === "/[_slug]" &&
    router.query._slug &&
    typeof router.query._slug === "string"
      ? `/${router.query._slug.replace(ReferralEnums.REDIRECT, "")}`
      : router.pathname;

  const { utm_campaign, utm_source, utm_medium, utm_content, utm_term } =
    router.query;

  // extracted "code" out of onSubmit because it is used in the
  // button (leads to /offers/list) that is shown if a user IS
  // opted in to Aisle.
  const code = `partnersModalDualOptIn:${campaignPhoneNumber}:${landingPageSource.substring(
    1
  )}`;

  const { browserLatitude, browserLongitude } = useGeolocationData();

  const subscribeOfferMethod = useSubscribeOfferMutation();

  const [isOpen, setIsOpen] = React.useState(
    isSubscriptionFormSubmittedSuccessfully
  );

  const bgImagePaths = [
    "/images/logos/logo-aurabora.png",
    "/images/logos/logo-cabinet.png",
    "/images/logos/logo-daring.png",
    "/images/logos/logo-hopwtr.png",
    "/images/logos/logo-vitacoco.png",
    "/images/logos/logo-flings.png",
    "/images/logos/logo-aurabora.png",
    "/images/logos/logo-cabinet.png",
    "/images/logos/logo-supercoffee.png",
    "/images/logos/logo-daring.png",
    "/images/logos/logo-flings.png",
    "/images/logos/logo-hopwtr.png",
    "/images/logos/logo-supercoffee.png",
    "/images/logos/logo-vitacoco.png",
    "/images/logos/logo-wow-bao.png",
  ];

  const handleOpenChange = (open: boolean) => {
    if (!open) {
      //@ts-expect-error grabbing the global gtag function
      window.gtag("event", "close_offers_modal", {
        send_to: `${gtag.AISLE_TRACKING_ID}`,
      });
    }
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    setIsOpen(isSubscriptionFormSubmittedSuccessfully);
  }, [isSubscriptionFormSubmittedSuccessfully, isUserOptedIn]);

  const onSubmit = async (payload: OffersPageFormSchemaInterface) => {
    const { phoneNumber: payloadPhoneNumber, zipCode } = payload;
    setCustomerPhoneNumber(payloadPhoneNumber);

    try {
      await subscribeOfferMethod.mutateAsync({
        customerPhoneNumber: payloadPhoneNumber,
        zipCode,
        longitude: browserLongitude ? browserLongitude : undefined,
        latitude: browserLatitude ? browserLatitude : undefined,
        // substring removes the leading "/" from the path
        // e.g. partnersModalDualOptIn:5856363422:actual-veggies
        influencerTag: "offers page",
        utmCampaign:
          typeof utm_campaign === "string"
            ? utm_campaign
            : typeof storedQueryParams.utm_campaign === "string"
            ? storedQueryParams.utm_campaign
            : undefined,
        utmSource:
          typeof utm_source === "string"
            ? utm_source
            : typeof storedQueryParams.utm_source === "string"
            ? storedQueryParams.utm_source
            : undefined,
        utmMedium:
          typeof utm_medium === "string"
            ? utm_medium
            : typeof storedQueryParams.utm_medium === "string"
            ? storedQueryParams.utm_medium
            : undefined,
        utmContent:
          typeof utm_content === "string"
            ? utm_content
            : typeof storedQueryParams.utm_content === "string"
            ? storedQueryParams.utm_content
            : undefined,
        utmTerm:
          typeof utm_term === "string"
            ? utm_term
            : typeof storedQueryParams.utm_term === "string"
            ? storedQueryParams.utm_term
            : undefined,
        referrerUrl: referrerUrl ? referrerUrl : undefined,
        // below are hardcoded values specific to the aisle campaign
        brandApiKey: AISLE_CAMPAIGN_BRAND_API_KEY,
        campaignPhoneNumber: AISLE_CAMPAIGN_PHONENUMBER,
      });
    } catch (error) {
      console.log(error);
    }
    //@ts-expect-error grabbing the global gtag function
    window.gtag("event", "generate_lead_offers", {
      send_to: `${gtag.AISLE_TRACKING_ID}`,
    });
    setIsOpen(false);

    // code powers the influencer tag for each offers/list page signup. This override will make the influencer tag between aisle conversation and offer page signups the same
    router.push({
      pathname: `/offers/list`,
      query: {
        ...router.query,
        code,
        referrerUrl: referrerUrl ? referrerUrl : undefined,
      },
    });
  };

  return (
    <Dialog open={isOpen && isPartner} onOpenChange={handleOpenChange}>
      <DialogContent className="max-w-[360px] lg:max-w-[864px] grid grid-cols-12 gap-0 p-0 rounded-3xl overflow-clip">
        <section className="relative col-span-12 px-12 py-8 lg:col-span-7 bg-gradient-to-t from-white">
          <div className="absolute -z-[1] opacity-20 flex flex-wrap gap-6 -rotate-45 -right-24 -top-12">
            {bgImagePaths.map((imgPath, idx) => {
              return (
                <Image
                  key={idx}
                  width={150}
                  height={100}
                  className="object-contain mx-auto grayscale"
                  alt="Bg logo"
                  src={imgPath}
                />
              );
            })}
          </div>
          <Image
            width={177}
            height={214}
            className="mx-auto"
            alt="Aisle Logo"
            src={"/images/aisle-logo.svg"}
          />
          <h3 className="text-3xl font-black text-center">
            Access 100+ offers from your favorite brands with just a click!
          </h3>
          {isUserOptedIn ? (
            <div className="w-full p-4">
              <Button
                className="w-full"
                onClick={() => {
                  //@ts-expect-error grabbing the global gtag function
                  window.gtag("event", "view_offers_modal", {
                    send_to: `${gtag.AISLE_TRACKING_ID}`,
                  });
                  router.push({
                    pathname: `/offers`,
                    query: {
                      code,
                      referrerUrl: referrerUrl ? referrerUrl : undefined,
                    },
                  });
                }}
              >
                View Offers
              </Button>
            </div>
          ) : (
            <PhoneNumberAndZipForm
              defaultCustomerPhoneNumber={customerPhoneNumber}
              isSubmitting={subscribeOfferMethod.isLoading}
              onSubmit={onSubmit}
              isZipCodeRequired={false}
              isBrowserGeolocationAccepted={false}
            />
          )}
          {}
        </section>
        <aside className="relative hidden h-full px-8 pt-16 border-l border-purple-100 lg:block lg:col-span-5 bg-gradient-to-r from-purple-50 to-slate-50">
          <LiveTransactionFeed isActive={isOpen} />
          <div className="absolute bottom-0 left-0 gap-4 items-center right-0 flex bg-gradient-to-t  from-90% from-purple-50  px-8 mb-9">
            <div className="relative">
              <Image
                width={118}
                height={119}
                className="object-cover mx-auto border-2 rounded-full aspect-square border-brand-500"
                alt="User Avatar Image"
                src={"/images/logos/testimonial-avatar.png"}
              />
              <div className="absolute px-2 py-1 text-xs bg-white border-4 border-purple-500 rounded-full -bottom-2 -right-3">
                Olivia
              </div>
            </div>
            <blockquote className="text-sm">
              &quot;I saved over $25 in one grocery trip!&quot;
            </blockquote>
          </div>
        </aside>
      </DialogContent>
    </Dialog>
  );
};

export default JoinAisleModal;
